import axios from 'axios';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import { invoicesActions } from './reducer';
import { withRouter } from 'react-router-dom';
import Modal from '../../commons/Components/Modal';
import React, { useEffect, useState } from 'react';
import { getChipColor } from '../../utils/guideStatus';
import { formatDate, getItemInStorage } from '../../utils/functions';
import Facturama from '../../commons/Components/Facturama';
import { actionProps, selectState } from '../../utils/reduxActions';
import { invoiceActions } from '../../commons/Components/Facturama/reducer';
import { DataGrid, GridColDef, GridToolbarContainer } from '@mui/x-data-grid';
import { SERVER_URL, APPLICATION_KEY, SECRET_KEY } from '../../utils/constants';

interface Guide {
  id?: string;
  _id?: string;
  serviceName: string;
  destination: string;
  trackingNumber: string;
  createdAt: string;
  actions: any;
  guideUrl: string;
  guideUrl6x4?: string;
  transaction?: {
    _id: string;
  };
}

interface InvoicesProps {
  actions?: any;
  controls?: any;
  history?: any;
  list?: Guide[];
  report?: any;
  invoiceControls?: any;
  profile?: any;
}

const CustomToolbar = () => {
  return <GridToolbarContainer></GridToolbarContainer>;
};

const Invoices: React.FC<InvoicesProps> = props => {
  const { actions, list, controls, invoiceControls } = props;
  let [isOpenModalInvoice, setIsOpenModalInvoice] = useState(false);
  const openModal = () => {
    setIsOpenModalInvoice(true);
  };
  const createInvoice = (data: any) => {
    actions.fieldsChanged({
      transactionIds: [],
      paymentForm: '',
      guides: [],
      serviceName: [],
    });
    openModal();
  };

  const getInvoiceByFormat = async (format: string, data: any) => {
    const cfdiId = data?.Id || data?.transaction?.invoiceId;
    const token = getItemInStorage('token');
    axios
      .get(`${SERVER_URL}/invoice/format?format=${format}&id=${cfdiId}`, {
        headers: {
          application: APPLICATION_KEY,
          secret: SECRET_KEY,
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      .then(response => {
        console.log('%c⧭ response', 'color: #ace2e6', response);
        if (response.data.data.Content) {
          const byteCharacters = atob(response.data.data.Content);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], {
            type: `application/${format}`,
          });

          // Create a URL for the Blob and open it in a new tab
          const fileURL = URL.createObjectURL(blob);
          window.open(fileURL);
        } else {
          Swal.fire({
            title: 'No se encontró factura',
            text: 'Favor de contactar al administrador',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok',
          });
        }
      })
      .catch(error => {
        console.log('%c⧭ error', 'color: #9c66cc', error);
        Swal.fire({
          title: 'Ocurrió un error',
          text: error.toString(),
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok',
        });
      });
  };

  const handlerCancelInvoice = (data: any) => {
    Swal.fire({
      title: 'Desea cancelar la Factura?',
      text: '¡No podrás revertir esto!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, cancelar!',
      cancelButtonText: 'No, abortar!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        const token = getItemInStorage('token');
        Swal.showLoading();
        axios
          .post(
            `${SERVER_URL}/invoice/cancel`,
            { id: data?.transaction?._id || data?.Id },
            {
              headers: {
                application: APPLICATION_KEY,
                secret: SECRET_KEY,
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            },
          )
          .then(res => {
            Swal.fire(
              'Factura cancelada!',
              'Tu factura ha sido eliminada correctamente.',
              'success',
            ).then(() => {
              window.location.reload();
            });
          })
          .catch(err => {
            console.log(err.message);
            Swal.fire(
              'Error!',
              'Se ha producido un error mientras se cancelaba la factura. vuelve a intentarlo mas tarde.',
              'error',
            );
          });
      },
    });
  };

  useEffect(() => {
    actions.getInvoicesList();
  }, []);

  useEffect(() => {
    if (invoiceControls.success) {
      setIsOpenModalInvoice(false);
      actions.getInvoicesList();
    }
  }, [invoiceControls.success, actions]);

  const columns: GridColDef[] = [
    {
      field: 'Date',
      headerName: 'Fecha de emisión',
      minWidth: 160,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'text-lg text-bold',
      valueGetter: (params: any) => params.row.Date,
      valueFormatter: (params: any) => formatDate(params.value),
    },
    {
      field: 'invoice.Folio',
      headerName: 'Folio de factura',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 140,
      headerClassName: 'text-lg text-bold',
      valueGetter: (params: any) => params.row.Folio,
    },
    {
      field: 'trackingNumber',
      headerName: 'No. Rastreo',
      type: 'number',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 130,
      headerClassName: 'text-lg text-bold',
      valueGetter: (params: any) => params?.row?.trackingNumber || 'N/A',
    },
    {
      field: 'invoice.PaymentTerms',
      headerName: 'Forma de pago',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      headerClassName: 'text-lg text-bold',
      valueGetter: (params: any) => params.row.PaymentTerms || 'N/A',
    },
    {
      field: 'invoice.Total',
      headerName: 'Monto facturado',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 140,
      headerClassName: 'text-lg text-bold',
      valueGetter: (params: any) => params.row.Total ?? 0,
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      flex: 1,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'text-lg text-bold',
      sortable: false,
      minWidth: 100,
      renderCell: data => {
        return (
          <div>
            {(data.row?.transaction?.invoiced ||
              data.row?.Status == 'active') && (
              <div className='grid grid-cols-4 grid-flow-col gap-1 grid-flow-row-dense'>
                <Tooltip
                  title={'Cancelar Factura'}
                  arrow
                >
                  <div>
                    <button
                      className={`${
                        !getChipColor(data.row.internalStatus)
                          ? 'bg-primary-inabit-disabled'
                          : 'bg-red-500 hover:bg-red-600'
                      } text-center p-2 text-white hover:text-white w-full h-full flex justify-center items-center rounded-lg`}
                      onClick={() => handlerCancelInvoice(data.row)}
                      disabled={!getChipColor(data.row.internalStatus)}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        strokeWidth='1.5'
                        stroke='currentColor'
                        className='w-6 h-6'
                      >
                        <path
                          strokeLinecap='round'
                          strokeLinejoin='round'
                          d='M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z'
                        />
                      </svg>
                    </button>
                  </div>
                </Tooltip>
                <Tooltip
                  title='Descargar Factura PDF'
                  arrow
                >
                  <div className='bg-primary-inabit-blue hover:bg-primary-inabit-blue-hover rounded-lg h-12 shadow flex justify-center items-center'>
                    <button
                      className='text-center p-4 text-white hover:text-white'
                      onClick={() => {
                        getInvoiceByFormat('pdf', data.row);
                      }}
                      disabled={!getChipColor(data.row.internalStatus)}
                    >
                      <svg
                        width='30px'
                        height='30px'
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                        stroke='#FFFFFF'
                      >
                        <g
                          id='SVGRepo_bgCarrier'
                          stroke-width='0'
                        ></g>
                        <g
                          id='SVGRepo_tracerCarrier'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        ></g>
                        <g id='SVGRepo_iconCarrier'>
                          {' '}
                          <path
                            d='M4 4C4 3.44772 4.44772 3 5 3H14H14.5858C14.851 3 15.1054 3.10536 15.2929 3.29289L19.7071 7.70711C19.8946 7.89464 20 8.149 20 8.41421V20C20 20.5523 19.5523 21 19 21H5C4.44772 21 4 20.5523 4 20V4Z'
                            stroke='#FFFFFF'
                            stroke-width='2'
                            stroke-linecap='round'
                          ></path>{' '}
                          <path
                            d='M20 8H15V3'
                            stroke='#FFFFFF'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                          ></path>{' '}
                          <path
                            d='M11.5 13H11V17H11.5C12.6046 17 13.5 16.1046 13.5 15C13.5 13.8954 12.6046 13 11.5 13Z'
                            stroke='#FFFFFF'
                            stroke-width='1.5'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                          ></path>{' '}
                          <path
                            d='M15.5 17V13L17.5 13'
                            stroke='#FFFFFF'
                            stroke-width='1.5'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                          ></path>{' '}
                          <path
                            d='M16 15H17'
                            stroke='#FFFFFF'
                            stroke-width='1.5'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                          ></path>{' '}
                          <path
                            d='M7 17L7 15.5M7 15.5L7 13L7.75 13C8.44036 13 9 13.5596 9 14.25V14.25C9 14.9404 8.44036 15.5 7.75 15.5H7Z'
                            stroke='#FFFFFF'
                            stroke-width='1.5'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                          ></path>{' '}
                        </g>
                      </svg>
                    </button>
                  </div>
                </Tooltip>
                <Tooltip
                  title='Descargar Factura XML'
                  arrow
                >
                  <div className='bg-primary-inabit-blue hover:bg-primary-inabit-blue-hover rounded-lg h-12 shadow flex justify-center items-center'>
                    <button
                      className='text-center p-4 text-white hover:text-white'
                      onClick={() => {
                        getInvoiceByFormat('xml', data.row);
                      }}
                      disabled={!getChipColor(data.row.internalStatus)}
                    >
                      <svg
                        height='30px'
                        width='30px'
                        version='1.1'
                        id='_x32_'
                        viewBox='0 0 512 512'
                        fill='#FFFFFF'
                      >
                        <g
                          id='SVGRepo_bgCarrier'
                          stroke-width='0'
                        ></g>
                        <g
                          id='SVGRepo_tracerCarrier'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        ></g>
                        <g id='SVGRepo_iconCarrier'>
                          <g>
                            <path d='M378.406,0H208.29h-13.176l-9.314,9.314L57.013,138.102l-9.314,9.314v13.176v265.514 c0,47.36,38.528,85.895,85.895,85.895h244.812c47.368,0,85.895-38.535,85.895-85.895V85.896C464.301,38.528,425.773,0,378.406,0z M432.49,426.105c0,29.877-24.214,54.091-54.084,54.091H133.594c-29.877,0-54.091-24.214-54.091-54.091V160.591h83.717 c24.884,0,45.07-20.178,45.07-45.07V31.804h170.115c29.87,0,54.084,24.214,54.084,54.092V426.105z'></path>
                            <path d='M178.002,297.743l21.051-30.701c1.361-2.032,2.032-4.07,2.032-6.109c0-5.027-3.938-8.965-9.37-8.965 c-3.394,0-6.11,1.494-8.281,4.754l-16.575,24.452h-0.265l-16.576-24.452c-2.172-3.26-4.888-4.754-8.281-4.754 c-5.432,0-9.37,3.938-9.37,8.965c0,2.039,0.67,4.077,2.031,6.109l20.919,30.701l-22.546,33.138 c-1.355,2.039-2.039,4.077-2.039,6.116c0,5.027,3.938,8.965,9.371,8.965c3.393,0,6.116-1.494,8.288-4.755l18.203-26.896h0.265 l18.203,26.896c2.171,3.261,4.894,4.755,8.287,4.755c5.432,0,9.37-3.938,9.37-8.965c0-2.039-0.677-4.078-2.039-6.116 L178.002,297.743z'></path>
                            <path d='M291.016,251.968c-5.977,0-9.238,3.261-12.226,10.326l-19.284,44.547h-0.545l-19.697-44.547 c-3.121-7.066-6.382-10.326-12.358-10.326c-6.654,0-11.004,4.622-11.004,11.954v72.398c0,6.109,3.806,9.643,9.244,9.643 c5.153,0,8.958-3.534,8.958-9.643v-44.554h0.678l14.397,33.138c2.856,6.522,5.167,8.428,9.782,8.428 c4.615,0,6.927-1.906,9.782-8.428L283,291.766h0.684v44.554c0,6.109,3.666,9.643,9.098,9.643c5.432,0,9.098-3.534,9.098-9.643 v-72.398C301.88,256.59,297.67,251.968,291.016,251.968z'></path>
                            <path d='M373.211,327.355h-32.873c-0.544,0-0.824-0.272-0.824-0.816V262.56c0-6.381-4.203-10.592-9.915-10.592 c-5.837,0-10.04,4.21-10.04,10.592v72.532c0,5.976,3.938,10.054,10.04,10.054h43.611c6.102,0,10.04-3.666,10.04-8.965 C383.251,331.02,379.313,327.355,373.211,327.355z'></path>
                          </g>
                        </g>
                      </svg>
                    </button>
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className='w-full overflow-auto'>
      <div className='flex flex-row-reverse'>
        <button
          type='button'
          onClick={() => createInvoice({})}
          className='inline-flex float-right items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-lg text-white bg-primary-inabit-blue hover:bg-primary-inabit-blue-hover transition ease-in-out duration-150 mb-4'
        >
          Generar Factura
        </button>
      </div>
      {/* Invoice Modal */}
      <Modal
        onClose={() => setIsOpenModalInvoice(false)}
        isOpen={isOpenModalInvoice}
      >
        <Facturama />
      </Modal>
      <DataGrid
        autoHeight
        localeText={{
          toolbarDensity: 'Tamaño de Columnas',
          toolbarDensityLabel: 'Size',
          toolbarDensityCompact: 'Pequeño',
          toolbarDensityStandard: 'Medio',
          toolbarDensityComfortable: 'Grande',
          toolbarExport: 'Exportar',
          toolbarFilters: 'Filtros',
          toolbarColumns: 'Columnas',
        }}
        rows={list || []}
        columns={columns}
        pageSize={12}
        loading={controls.loading}
        className='rounded-xl'
        components={{ Toolbar: CustomToolbar }}
        density='comfortable'
      />
    </div>
  );
};

const withConnect = connect(
  selectState(
    'app.profile',
    'invoicesList.controls',
    'invoicesList.list',
    'invoicesList.report',
    'invoice.invoiceControls',
  ),
  actionProps({
    ...invoicesActions,
    ...invoiceActions,
  }),
);

export default withRouter(withConnect(Invoices));
